import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    AppBar as CovalenceAppBar,
    Modal,
    ModalContent
} from "@cpchem/covalence-ui";
import { LogoIcon } from "../../../components/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo } from "@fortawesome/pro-light-svg-icons";
import { HelpPageIcon } from "../../../components/icons/help-page-icon";
import { version } from "./../../../../package.json";
export function AppBar(): JSX.Element {
    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const navigate = useNavigate();

    function onHelpModalClosed() {
        setHelpModalVisible(false);
    }

    function onHelpPageItemClicked() {
        setHelpModalVisible(true);
    }
    const helpMenuContent = (
        <div className="feed-back-menu">
            <Button
                text={"Help Page"}
                icon={<FontAwesomeIcon icon={faSquareInfo} className="icon" />}
                variant="text"
                onClick={onHelpPageItemClicked}
                testId={""}
            />
        </div>
    );

    function handleIconClick() {
        navigate("/");
    }

    return (
        <>
            <CovalenceAppBar
                className="appbar"
                applicationName="File Scrubber"
                icon={<LogoIcon />}
                onIconClick={handleIconClick}
                testId={""}
                helpMenuItems={[
                    {
                        key: "helpMenu",
                        content: helpMenuContent,
                        testId: ""
                    }
                ]}
            />
            <Modal
                onRequestClose={onHelpModalClosed}
                title="CPChem File Scrubber"
                isOpen={helpModalVisible}
                className="help-modal"
                testId=""
            >
                <ModalContent testId="">
                    <div className="help-page-content">
                        <div className="help-page-image">
                            <HelpPageIcon />
                        </div>
                        <div className="help-page-version-build-info">
                            <div className="build-version-timestamp">
                                <p className="build-version-date-text">
                                    Version
                                </p>
                                <p data-testid="">{version}</p>
                            </div>
                        </div>
                        <div className="help-page-digital-workplace-hyperlink">
                            <p>
                                For more help, please utilize the{" "}
                                <a
                                    href="https://cpchem-myit.onbmc.com/dwp/"
                                    data-testid=""
                                >
                                    Digital Workplace Portal
                                </a>
                            </p>
                        </div>
                        <div className="help-page-copyright-text">
                            <p>
                                Copyright © 2024 by Chevron Phillips Chemical.
                                Company Confidential. For Internal Use Only.
                            </p>
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        </>
    );
}
