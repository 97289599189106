import React from "react";
import "./styles.scss";

export function UnauthenticatedPage(): JSX.Element {
    return (
        <div className="unauthenticated-page">
            <p>It appears your login has failed.</p>
        </div>
    );
}
