import React from "react";
import "./progress_bar.css";
interface ProgressBarProps {
    progress: number;
}
function ProgressBar({ progress }: ProgressBarProps) {
    return (
        <div className="progress-bar-container">
            {/* Set dynamic width based on progress */}
            <div
                className="progress-bar-fill"
                style={{ width: `${progress}%` }}
            />
            <span className="progress-bar-text">{progress}%</span>
        </div>
    );
}

export default ProgressBar;
