import { Config } from "@config";
import {
    LogManager,
    logsAtOrAbove,
    LogLevel,
    getAppVersionFromMetaTag
} from "@cpchem/logging";

export function configureLogging(config: Config): void {
    LogManager.initialize({
        appVersion: getAppVersionFromMetaTag(),
        appInsights: config.logging.appInsights
    });

    const environmentSpecificLogFilter = logsAtOrAbove(
        config.env.toUpperCase() === "DEVELOPMENT"
            ? LogLevel.INFO
            : LogLevel.DEBUG
    );

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter
    );
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}
