import React, { useEffect } from "react";
import { AuthManager } from "@cpchem/azure-auth";
import "./styles.scss";

export function LoginScreen(): JSX.Element {
    useEffect(() => {
        const authMgr = AuthManager.getInstance();
        authMgr.loginAsync();
    }, []);
    return <div className="login-screen">&nbsp;</div>;
}
