import { AlertOptions, useAlertBanner } from "@cpchem/covalence-ui";
import React from "react";

interface FileDropZoneProps {
    onFileDrop: (file: File) => void;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFileDrop }) => {
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        dismissAlertBanner();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            onFileDrop(droppedFile);
        } else {
            const alertOptions: AlertOptions = {
                message: "Please upload a valid JSON file",
                severity: "caution",
                dismissable: true
            };
            createAlertBanner(alertOptions); // Error callback
        }
    };

    return (
        <div
            data-testid="dropzone"
            style={{
                border: "2px dashed #ccc",
                borderRadius: "10px",
                padding: "50px",
                width: "300px",
                margin: "0 auto",
                backgroundColor: "#f9f9f9",
                cursor: "pointer"
            }}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
        >
            <p>Drag & Drop your JSON file here</p>
        </div>
    );
};

export default FileDropZone;
