import * as msal from "@azure/msal-browser";
import { LogLevel } from "@cpchem/logging";

export interface ProvidedConfig {
    api: {
        personalization: {
            url: string;
            scopes: string[];
        };
    };
    msal: msal.Configuration;
    graph: {
        photo: {
            endpoint: string;
            scopes: string[];
        };
    };
    logging: {
        appInsights: {
            connectionString: string;
            sinkPageViews?: boolean;
            sinkLogsAtOrAboveLevel?: LogLevel;
        };
        logRocketAppId: string;
    };
    mocks?: {
        mockUserEndpoint: string;
    };
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}

export function getProvidedConfig(env?: string): ProvidedConfig {
    return env === "development"
        ? {
              api: {
                  personalization: {
                      url: "https://api-dev.cpchem.com/personalization/v1/personalization",
                      scopes: [
                          "https://api-dev.cpchem.com/Personalization.FullAccess"
                      ]
                  }
              },
              msal: {
                  auth: {
                      authority:
                          "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                      clientId: "1afdd26d-ee5b-4f2e-ab5e-f6ef58a45b17",
                      redirectUri: "https://localhost:3000/auth_redirect"
                  }
              },
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=2a250b0d-1f94-4d6e-83f9-e395f48eabf6;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=8060a663-79f6-47ce-9c45-80f9337a1467",
                      sinkPageViews: true,
                      sinkLogsAtOrAboveLevel: LogLevel.ERROR
                  },
                  logRocketAppId: "cpchem/test"
              },
              graph: {
                  photo: {
                      endpoint: "https://graph.microsoft.com/v1.0",
                      scopes: ["https://graph.microsoft.com/User.Read.All"]
                  }
              },
              mocks: {
                  mockUserEndpoint: "http://localHost:59850/getMockUser"
              }
          }
        : getConfigFromIndex();
}
