import { trackEvent, LogLevel, log } from "@cpchem/logging";
const defaultRegex = [
    [
        // Redact signature on JWTs
        {
            regex: new RegExp(
                `\\b(ey[A-Za-z0-9-_=]+)\\.(ey[A-Za-z0-9-_=]+)\\.[A-Za-z0-9-_.+/=]+\\b`,
                "gi"
            )
        }
    ]
];

function buildRegex(word: string) {
    return [
        {
            // [full word]=:[capture]
            regex: new RegExp(
                `([\\s*_:]*${word}(?:["\\\\*"\\s*]*)*[:=]\\s*(?:[\\\\*"|"]*)?)([^"|^"|^&|^\\|^,|^\\\\]*)`,
                "gi"
            )
        },

        // Set up this way in case "value" isn't directly after "name"
        // {
        //    "name": "[word]",
        //    "something": "not wanted",
        //    "value": "[capture]"
        // }
        {
            regex: new RegExp(
                `([\\\\]?"name[\\\\]*"\\s*:\\s*[\\\\]*"${word}[\\\\]*"\\s*,\\s*.[\\\\]*.*?value[\\\\]*"\\s*:\\s*[\\\\"]?)([^"}\\\\]*)`,
                "gi"
            )
        },
        // "name" comes after "value"
        // {
        //    "value": "[capture]",
        //    "something": "not wanted",
        //    "name": "[word]"
        // }
        {
            regex: new RegExp(
                `([\\\\]?"value[\\\\]*"\\s*:\\s*[\\\\"]?)([^"}\\\\]*),*.*([\\\\]?"name[\\\\]*"\\s*:\\s*[\\\\]*"${word}[\\\\]*"\\s*)`,
                "gi"
            )
        }
    ];
}
export interface ContentResult {
    content: string;
    report: Map<string, number>;
}
function isValueAheadOfName(input_string: string): boolean {
    return input_string.indexOf("value") < input_string.indexOf("name");
}

function getQuotation(capture: string): string {
    if (capture.indexOf('\\\\"') >= 0) return '\\\\"';
    if (capture.indexOf('\\"') >= 0) return '\\"';
    if (capture.indexOf('"') >= 0) return '"';
    return "";
}

function processValueAheadOfName(
    content: string,
    report: Map<string, number>,
    capture: string,
    p3: string
): string {
    const regex = /[\\]*"name[\\]*"*:\s*[\\"|";:,?]*([^\\"'']*)/;
    const nameMatch = p3.match(regex);
    if (nameMatch === null) return content;
    // Increment the count for the keyword in the map
    report.set(
        (nameMatch[1] as string).toLowerCase(),
        (report.get((nameMatch[1] as string).toLowerCase()) || 0) + 1
    );
    const key = (nameMatch[1] as string).toUpperCase();
    const quotation = getQuotation(capture);
    const result = `${capture}${quotation}REDACT_CPCHEM_${key}${quotation},${p3}`;
    return result;
}

function isQuotationRequired(capture: string): boolean {
    return !(capture.endsWith('"') || capture.endsWith('\\"'));
}

function process_none_name_value(
    capture: string,
    report: Map<string, number>
): string {
    // capture the keywords
    const regex = /[^'":=\s*\\]+/;
    const catch_name = capture.match(regex);
    const name = catch_name ? catch_name[0]?.toLowerCase() : "";
    let result = "";
    if (name.startsWith("ey")) {
        const token = "AUTH_TOKEN";
        report.set(token, (report.get(token) || 0) + 1);
        result = `REDACT_CPCHEM_${token.toUpperCase()}`;
    } else {
        report.set(name, (report.get(name) || 0) + 1);
        const quotation = getQuotation(capture);
        result = `${capture}${quotation}REDACT_CPCHEM_${name.toUpperCase()}${quotation}`;
    }
    return result;
}

function process_name_value(
    capture: string,
    report: Map<string, number>
): string {
    const regex = /[\\]*"name[\\]*"*:\s*[\\"|";:,?]*([^\\"'']*)/;
    const nameMatch = capture.match(regex);

    const key_name = nameMatch ? nameMatch[1]?.toLowerCase() : "";
    // Increment the count for the keyword in the map
    report.set(key_name, (report.get(key_name) || 0) + 1);
    const red_key_name = key_name.toUpperCase();
    let result = "";
    if (isQuotationRequired(capture)) {
        const quotation = getQuotation(capture);
        result = `${capture}${quotation}REDACT_CPCHEM_${red_key_name}${quotation}}`;
    } else result = `${capture}REDACT_CPCHEM_${red_key_name}`;
    return result;
}

export function sanitize(
    content: string,
    wordsToScrub: Map<string, number>,
    onProgress: (progress: number) => void | null
): ContentResult {
    // build list of regexes needed to actually scrub the file
    const wordSpecificScrubList = Array.from(wordsToScrub.keys()).map((word) =>
        buildRegex(word)
    );
    const allScrubList = defaultRegex.concat(wordSpecificScrubList);
    const report = new Map<string, number>();
    let progress = 0;
    allScrubList.forEach((scrubList) => {
        progress += 1;
        if (onProgress) onProgress((progress * 100) / allScrubList.length);
        scrubList.forEach((scrub) => {
            content = content.replace(
                scrub.regex,
                (match, capture, _p2, p3) => {
                    let result = match;
                    if (result.indexOf("REDACT_CPCHEM") >= 0) return result;
                    if (isValueAheadOfName(content)) {
                        return processValueAheadOfName(
                            content,
                            report,
                            capture,
                            p3
                        );
                    }
                    // if match the name and value
                    if (capture.includes("name") && capture.includes("value")) {
                        result = process_name_value(capture, report);
                    } else {
                        result = process_none_name_value(capture, report);
                    }
                    return result;
                }
            );
        });
    });
    return { content, report };
}

// Utility function to process the file and replace keywords
export const processFile = (
    file: File,
    keywords: Map<string, number>,
    onProgress: (progress: number) => void,
    onReport: (report: Map<string, number>) => void,
    onSuccess: (downloadUrl: string) => void,
    onError: (error: string) => void
): void => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
        try {
            const jsonData = event.target?.result as string;
            const modifiedData = sanitize(jsonData, keywords, onProgress);
            const modifiedBlob = new Blob([modifiedData.content], {
                type: "application/json"
            });
            onReport(modifiedData.report);
            logReport(file.name, Array.from(modifiedData.report.entries()));
            const downloadLink = URL.createObjectURL(modifiedBlob);
            onSuccess(downloadLink);
        } catch (error) {
            log(`FileScrubber has erro : ${error}`, LogLevel.ERROR);
            onError("Error parsing JSON.");
        }
    };
    reader.readAsText(file);
};

function logReport(name: string, details: [string, number][]) {
    let reportStr = `process file ${name}: `;
    details.forEach((key, count) => {
        reportStr += `  ${count} ${key} `;
    });
    trackEvent({
        type: "PageView",
        contents: JSON.stringify({
            page: "File Scruber",
            params: { date: Date.now(), report: reportStr }
        })
    });
}
