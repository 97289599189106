import React, { useState, useEffect } from "react";
import keywordConfig from "../keywords-config.json";
import { AppBar } from "./components/template-app-bar";
import { processFile } from "../utilities/jsonProcessor";
import { FileDropZone, ProgressBar } from "../components";
import { JSX } from "react/jsx-runtime";
import { log, LogLevel, trackEvent } from "@cpchem/logging";
import {
    AlertBanner,
    AlertOptions,
    Grid,
    useAlertBanner
} from "@cpchem/covalence-ui";

export function App(): JSX.Element {
    const [downloadUrl, setDownloadUrl] = useState<string | undefined>(
        undefined
    );
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [keywords, setKeywords] = useState<Map<string, number>>(new Map());
    const [report, setReport] = useState<Map<string, number>>(new Map());
    const [progress, setProgress] = useState<number>(0);
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    useEffect(() => {
        const words_map = new Map();
        keywordConfig.keywords.forEach((w) => words_map.set(w, 0));
        setKeywords(words_map);
    }, []);

    const handleFileDrop = (file: File) => {
        dismissAlertBanner();
        setProgress(0);
        setFileName(file.name);
        processFile(
            file,
            keywords,
            (progress: number) => setProgress(progress),
            (report: Map<string, number>) => {
                report.forEach((count, keyword) => {
                    log(
                        `FileScrubber found Keyword: ${keyword}, Count: ${count}`,
                        LogLevel.DEBUG
                    );
                });
                setReport(report);
            },
            (downloadUrl: string) => setDownloadUrl(downloadUrl),
            (error: string) => {
                const alertOptions: AlertOptions = {
                    message: error,
                    severity: "danger",
                    dismissable: true
                };
                createAlertBanner(alertOptions);
            }
        );
    };

    return (
        <Grid className="app">
            <AppBar />
            <div className="app-container">
                <p className="welcome-message" data-testid="welcome">
                    Protect sensitive company data by sanitizing your files
                    before sharing. Simply drag and drop the file below. The app
                    will automatically sanitize the file. Currently supported
                    file types: .har .json .txt
                </p>
                {/* Drag-and-drop area */}
                <FileDropZone onFileDrop={handleFileDrop} />
                {progress > 0 && (
                    <ProgressBar
                        data-testid="progress_bar"
                        progress={progress}
                    />
                )}
                <div className="report-section">
                    <div>
                        {report.size > 0 && (
                            <p className="welcome-message" data-testid="">
                                The following sensitive information has been
                                found and removed from your file:
                            </p>
                        )}
                        <ul>
                            {[...report.entries()]
                                .filter(([, count]) => count > 0) // Only show if count > 0
                                .map(([keyword, count]) => (
                                    <li key={keyword}>
                                        {keyword}: {count}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <AlertBanner />
                    <div className="keyword-list">
                        {report.size > 0 && downloadUrl !== undefined && (
                            <p>File ready for download: {fileName}</p>
                        )}
                        <a href={downloadUrl} download={`redacted_${fileName}`}>
                            <button
                                className="cui-button solid large download-button success"
                                disabled={fileName === undefined}
                                data-testid="download"
                                onClick={() => {
                                    trackEvent({
                                        type: "DOWNLOAD",
                                        contents: JSON.stringify({
                                            page: "File Scruber",
                                            params: {
                                                date: Date.now(),
                                                report: `download ${fileName}`
                                            }
                                        })
                                    });
                                }}
                            >
                                Download Modified File
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </Grid>
    );
}
export default App;
